import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import "./ButtonsHeader.css";

const SummaryButtons = ({
  userJournals,
  onOneDaySummary,
  onOneWeekSummary,
  onTwoWeekSummary,
  onOneMonthSummary,
  onGenerateSummary,
}) => {
  const isButtonVisible = (daysAgo) => {
    if (!userJournals || userJournals.length === 0) {
      return false;
    }
    const dateAgo = new Date();
    dateAgo.setDate(dateAgo.getDate() - daysAgo);

    return userJournals.some((journal) => {
      const journalDate = new Date(journal.created_at); // Adjust this if the property name is different
      return journalDate >= dateAgo;
    });
  };

  return (
    <div className="summaryButtons">
      {isButtonVisible(7) && (
        <button className="gradientButton" onClick={onOneWeekSummary}>
          One Week Summary
        </button>
      )}
      {isButtonVisible(14) && (
        <button className="gradientButton" onClick={onTwoWeekSummary}>
          Two Week Summary
        </button>
      )}
      {isButtonVisible(30) && (
        <button className="gradientButton" onClick={onOneMonthSummary}>
          One Month Summary
        </button>
      )}
      {userJournals && userJournals.length > 0 && (
        <button className="gradientButton" onClick={onGenerateSummary}>
          Generate Summary for selected dates
        </button>
      )}
    </div>
  );
};

export default SummaryButtons;
