import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./PushNotificationList.css";
import empathProfile from '../../../../../src/empathProfile.png';
import AddPushNotificationModal from './AddPushNotificationModal';
import moment from 'moment';

const PushNotificationList = ({ user }) => {
    const [addingPushNotif, setAddingPushNotif] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [sendDate, setSendDate] = useState(new Date());
    const [sendTime, setSendTime] = useState(null);
    const [sendNow, setSendNow] = useState(false);

    useEffect(() => {
        const getNotifications = async () => {
            await fetchPushNotifications();
        };
        getNotifications();
    }, [user]);

    const fetchPushNotifications = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/pushNotifications/fetchPushNotifications`,
                {
                    userId: user.id,
                },
                { withCredentials: true }
            );
            console.log(response);
            setIsLoading(false);
            setNotifications(response.data);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const handleModalClose = () => {
        setAddingPushNotif(false);
    };

    const handleSendNotification = async () => {
        if (!title || !body) {
            alert("Please select a valid date and time for scheduling the notification.");
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        try {
            const payload = {
                userId: user.id,
                title,
                body,
                sendNow,
            };
    
            if (!sendNow) {
                payload.sendDate = combineDateAndTime(sendDate, sendTime.value);
                console.log(payload.sendDate);
            }

            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/pushNotifications/sendPushNotification`,
                payload,
                { withCredentials: true }
            );
    
            console.log(response);
            setIsLoading(false);
            setAddingPushNotif(false);
            setTitle('');
            setBody('');
            setSendDate(new Date());
            setSendTime(null);
            setSendNow(false);
    
            await fetchPushNotifications();
        } catch (error) {
            console.error('Error sending notification:', error);
            alert("An error occurred while sending the notification. Please try again.");
            setIsLoading(false);
        }
    };

    const isValidDate = (date) => {
        return moment(date, moment.ISO_8601, true).isValid();
    };

    const combineDateAndTime = (sendDate, sendTime) => {
        // Ensure sendDate is a valid date object
        if (!(sendDate instanceof Date) || isNaN(sendDate)) {
            throw new Error('Invalid sendDate');
        }    
        // Parse sendTime to extract hours and minutes
        const tim = parseTimeString(sendTime);
        if (isNaN(tim.hours) || isNaN(tim.minutes)) {
            throw new Error('Invalid sendTime');
        }
    
        // Create a new Date object and set the hours, minutes, seconds, and milliseconds
        const combinedDateTime = new Date(sendDate);
        combinedDateTime.setHours(tim.hours);
        combinedDateTime.setMinutes(tim.minutes);
        combinedDateTime.setSeconds(0);
        combinedDateTime.setMilliseconds(0);
        return combinedDateTime;
    };

    const parseTimeString = (timeString) => {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':');
        
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return { hours: parseInt(hours, 10), minutes: parseInt(minutes, 10) };
    };

    return (
        <div className="push-notification-list">
            {notifications.map(notification => (
                <div key={notification.notificationId} className="push-notification-list-item">
                    <img src={empathProfile} alt="Notification" className="push-notification-list-item-image" />
                    <div className="push-notification-list-item-content">
                        <div className="push-notification-list-item-title">{notification.title}</div>
                        <div className="push-notification-list-item-body">{notification.body}</div>
                    </div>
                    <div className="push-notification-list-item-info">
                        {!isValidDate(notification.sentAt) ? (
                            <div className="push-notification-list-item-date">
                                {moment(notification.scheduledAt).local().format('MMMM Do YYYY, h:mm A')}
                            </div>
                        ) : (
                            <div className="push-notification-list-item-date">
                                {moment(notification.sentAt).local().format('MMMM Do YYYY, h:mm A')}
                            </div>
                        )}
                        <div className={`push-notification-list-item-status ${notification.userOpened ? 'opened' : !isValidDate(notification.sentAt) ? 'scheduled' : 'sent'}`}>
                            {notification.userOpened ? "Opened" : !isValidDate(notification.sentAt) ? "Scheduled" : "Sent"}
                        </div>
                    </div>
                </div>
            ))}
            <button onClick={() => setAddingPushNotif(true)} className="journal-prompt-row-add-button">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    &#43; Push Notification
                </div>
            </button>
            <AddPushNotificationModal
                show={addingPushNotif}
                handleClose={handleModalClose}
                handleSubmit={handleSendNotification}
                title={title}
                setTitle={setTitle}
                body={body}
                setBody={setBody}
                sendDate={sendDate}
                setSendDate={setSendDate}
                sendTime={sendTime}
                setSendTime={setSendTime}
                sendNow={sendNow}
                setSendNow={setSendNow}
            />
        </div>
    );
}

export default PushNotificationList;
