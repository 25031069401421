import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaMobileAlt, FaSync, FaBell, FaLock, FaChartLine, FaPhone, FaApple, FaGooglePlay } from 'react-icons/fa';
import EmpathVideoDemo from './EmpathDemoVideo.mp4';

const MobileAppSection = () => {
  return (
    <section id="mobile-app" className="mobile-app-section">
      <Container>
        <h2 className="section-title text-center mb-5">Take our journaling app for a spin</h2>
        <Row className="align-items-center">
          <Col lg={6} className="mb-4 mb-lg-0">
            <div className="mobile-app-video-container">
              <video 
                className="mobile-app-video"
                autoPlay 
                loop 
                muted 
                playsInline
              >
                <source src={EmpathVideoDemo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
          <Col lg={6}>
            <h3 className="mb-4">Empowering Mental Wellness Anytime, Anywhere</h3>
            <p className="mb-4">
              With the Empath mobile app, your journals are always within reach. Whether you're reflecting on your day or tracking your daily journey, Empath makes it easier to stay connected with your thoughts—and your therapist.
            </p>
            <Row>
              {[
                { icon: <FaMobileAlt />, title: "Intuitive Design", description: "A seamless, user-friendly experience that makes journaling a breeze." },
                { icon: <FaSync />, title: "Real-Time Sync", description: "Your journals sync instantly, keeping your therapist updated and ensuring continuity in your care." },
                { icon: <FaBell />, title: "Personalized Reminders", description: "Receive gentle nudges to reflect on your thoughts, so you never miss a moment of mindfulness." },
                { icon: <FaLock />, title: "Secure & Private", description: "Your data is safeguarded with top-tier security, ensuring your privacy and peace of mind." },
                { icon: <FaChartLine />, title: "Mood Tracking", description: "Visualize your emotional patterns with insightful mood charts and share them with your therapist." },
                { icon: <FaPhone />, title: "Voice Journaling", description: "Record your thoughts on the go with just a call—perfect for capturing spontaneous reflections." }
              ].map((feature, index) => (
                <Col key={index} sm={6} className="mb-3">
                  <Card className="h-100 border-0 shadow-sm">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <div className="mobile-feature-icon me-3">
                          {feature.icon}
                        </div>
                        <h5 className="mb-0">{feature.title}</h5>
                      </div>
                      <Card.Text>{feature.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="mt-4 text-center text-lg-start">
          <Button 
            href="https://apps.apple.com/us/app/myempath/id6472873287" 
            target="_blank" 
            rel="noopener noreferrer" 
            variant="dark" 
            className="me-3 mb-2 app-store-btn"
          >
            <FaApple className="me-2" /> Download on the App Store
          </Button>
        </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MobileAppSection;