import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';
import './AddPushNotificationModal.css';

const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 5) { // Step by 5 minutes
            const timeString = moment({ hour, minute }).format('h:mm A');
            times.push({ value: timeString, label: timeString });
        }
    }
    return times;
}

const AddPushNotificationModal = ({
    show,
    handleClose,
    handleSubmit,
    title,
    setTitle,
    body,
    setBody,
    sendDate,
    setSendDate,
    sendTime,
    setSendTime,
    sendNow,
    setSendNow
}) => {
    const timeOptions = generateTimeOptions();

    return (
        <Modal show={show} onHide={handleClose} centered backdrop="static" dialogClassName="custom-modal">
            <Modal.Header closeButton>
                <Modal.Title>Create Push Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label>
                    Title:
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className="form-control" />
                </label>
                <label>
                    Body:
                    <textarea value={body} onChange={(e) => setBody(e.target.value)} className="form-control"></textarea>
                </label>
                <div className="send-now-container">
                    <label>Send Now:</label>
                    <label className="switch">
                        <input type="checkbox" checked={sendNow} onChange={(e) => setSendNow(e.target.checked)} />
                        <span className="slider"></span>
                    </label>
                </div>
                <div className={`toggle-content ${!sendNow ? 'show' : ''}`}>
                    <label>
                        Date of Sending:
                        <DatePicker selected={sendDate} onChange={(date) => setSendDate(date)} className="form-control" />
                    </label>
                    <label>
                        Time of the Day:
                        <Select
                            options={timeOptions}
                            value={sendTime}
                            onChange={(option) => setSendTime(option)}
                            className="form-control"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                    {sendNow ? 'Send Now' : 'Schedule'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddPushNotificationModal;
