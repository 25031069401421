import React from 'react';
import FeedbackSection from '../Feedback/FeedbackSection';
import "../Dashboard.css";
import ReactMarkdown from 'react-markdown';

const SummarySection = ({ userSummary, isGeneratingSummary, journalIdMap, userJournals, setExpandedJournalIndex }) => {
    const parseSummaryText = (summaryText) => {
        const regex = /(\[\d+(?:, \d+)*\])/g; // Matches [1] and [3, 5] and [1, 2, 4, 6]
        let parts = [];
        let lastIndex = 0;
    
        summaryText.replace(regex, (match, p1, offset) => {
          // Add the text before the match
          if (lastIndex !== offset) {
            parts.push({
              type: "text",
              value: summaryText.slice(lastIndex, offset),
            });
          }
          // Add the match
          parts.push({ type: "ref", value: p1 });
          lastIndex = offset + p1.length;
        });
    
        // Add any remaining text after the last match
        if (lastIndex < summaryText.length) {
          parts.push({ type: "text", value: summaryText.slice(lastIndex) });
        }
    
        return parts;
      };


  const scrollToJournal = (sequentialJournalId) => {
    // Convert sequentialJournalId to number since keys in journalIdMap might be stored as strings
    sequentialJournalId = Number(sequentialJournalId);

    // Use the journalIdMap to find the original journal ID
    const originalJournalId = journalIdMap[sequentialJournalId];

    if (originalJournalId) {
      const journalElement = document.querySelector(
        `.journalEntry[data-journal-id="${originalJournalId}"]`
      );
      console.log("journalElement", journalElement);

      if (journalElement) {
        journalElement.scrollIntoView({ behavior: "smooth", block: "center" });

        const index = userJournals.findIndex(
          (journal) => journal.journal_id === originalJournalId
        );

        if (index !== -1) {
          setExpandedJournalIndex(index);
        }
      }
    } else {
      console.log(`No mapping found for sequential ID: ${sequentialJournalId}`);
    }
  };

  const renderSummaryWithReferences = (summaryText) => {
    if (typeof summaryText !== "string") {
      // Handle the case when summaryText is not a string
      // You can convert it to a string or handle it based on its data type
      summaryText = summaryText.toString();
    }

    const bulletPoints = summaryText
      .split("•")
      .filter((point) => point.trim() !== "");

    return (
      <ul className="summaryText">
        {bulletPoints.map((point, index) => {
          const parts = parseSummaryText(point);

          return (
            <li key={index}>
              {parts.map((part, partIndex) => {
                if (part.type === "ref") {
                  const ids = part.value.replace(/[\[\]]/g, "").split(", ");

                  return (
                    <span key={partIndex}>
                      {ids.map((id, idIndex) => (
                        <JournalReference
                          key={idIndex}
                          journalId={id.trim()}
                          scrollToJournal={scrollToJournal}
                        />
                      ))}
                    </span>
                  );
                } else {
                  return <span key={partIndex}>{part.value}</span>;
                }
              })}
            </li>
          );
        })}
      </ul>
    );
  };

  const JournalReference = ({ journalId, scrollToJournal }) => {
    return (
      <span
        className="journalReference"
        onClick={() => scrollToJournal(journalId)}
        style={{
          textDecoration: "underline",
          cursor: "pointer",
          marginRight: "5px",
        }}
      >
        [{journalId}]
      </span>
    );
  };

  if (!isGeneratingSummary && !userSummary) {
    return null;
  }

  return (
    <div>
      {isGeneratingSummary ? (
        <div className="summaryLoadingIndicator">
          <div className="spinner">
            <div className="spinner-face"></div>
            <div className="spinner-face"></div>
            <div className="spinner-face"></div>
          </div>
          <p>Generating summary...</p>
        </div>
      ) : userSummary ? (
        <div className="summaryBlock">
          <div className="summaryText">
            <ReactMarkdown>{userSummary}</ReactMarkdown> 
          </div>
          <FeedbackSection />
        </div>
      ) : null}
    </div>
  );
}

export default SummarySection;