import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faDownload } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";

const Success = () => {
    const location = useLocation();

    const handleContinue = () => {
        window.location.href = 'https://apps.apple.com/us/app/myempath/id6472873287'; 
    };

    const isExistingUser = location.search.includes("existing=true");

    return (
        <div className="success-page" style={{
            fontFamily: "'Inter', sans-serif",
            color: '#333',
            minHeight: '100vh',
            background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden'
        }}>
            <div className="success-background" style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 0
            }}>
                <div className="success-shape success-shape-1" style={{
                    position: 'absolute',
                    width: '300px',
                    height: '300px',
                    borderRadius: '50%',
                    background: 'rgba(100, 200, 255, 0.1)',
                    top: '-100px',
                    right: '-100px'
                }}></div>
                <div className="success-shape success-shape-2" style={{
                    position: 'absolute',
                    width: '200px',
                    height: '200px',
                    borderRadius: '50%',
                    background: 'rgba(255, 100, 100, 0.1)',
                    bottom: '-50px',
                    left: '-50px'
                }}></div>
            </div>
            <Container style={{ position: 'relative', zIndex: 1 }}>
                <Row className="justify-content-center">
                    <Col md={8} lg={6}>
                        <div className="success-card" style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.95)',
                            borderRadius: '10px',
                            padding: '40px',
                            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                            textAlign: 'center'
                        }}>
                            <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '4rem', color: '#28a745', marginBottom: '1rem' }} />
                            <h2 style={{
                                fontSize: '2.5rem',
                                fontWeight: 700,
                                color: '#007bff',
                                marginBottom: '1.5rem'
                            }}>
                                {isExistingUser ? 
                                    "Account Linked Successfully" :
                                    "Account Created Successfully"
                                }
                            </h2>
                            <p style={{ fontSize: '1.1rem', marginBottom: '2rem' }}>
                                {isExistingUser ? 
                                    "Your account has been successfully linked. You can continue accessing the mobile app using your existing credentials." :
                                    "Your account has been successfully created. You can now continue signing into the mobile app using these credentials."
                                }
                            </p>
                            <Button 
                                variant="primary" 
                                onClick={handleContinue}
                                className="py-2 px-4 fw-bold"
                                style={{
                                    backgroundColor: '#007bff',
                                    borderColor: '#007bff',
                                    transition: 'all 0.3s ease',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onMouseEnter={(e) => e.target.style.transform = 'translateY(-2px)'}
                                onMouseLeave={(e) => e.target.style.transform = 'translateY(0)'}
                            >
                                <FontAwesomeIcon icon={faDownload} className="me-2" />
                                Download Mobile App
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Success;