import { useState, useEffect } from 'react';
import axios from 'axios';

const useClientData = (selectedUser, startDate, endDate) => {
  const [userJournals, setUserJournals] = useState(null);
  const [healthData, setHealthData] = useState(null);
  const [userSummary, setUserSummary] = useState(null);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [journalIdMap, setJournalIdMap] = useState({});
  const [isMoodChartLoading, setIsMoodChartLoading] = useState(false);
  const [emotionData, setEmotionData] = useState([
    { category: "Alert/Anxious", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Excited/Elated", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Pleased", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Content/Relaxed", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Calm/Indifferent", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Depressed/Bored", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Dissapointed", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Angry/Frustrated", feelingValue: 0.0, calculatedValue: 0.0 },
  ]);

  const retrieveClientJournalEntries = async (user, start, end) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/retrieveClientJournalEntries`,
        {
          userId: user.id,
          startDate: start,
          endDate: end,
        },
        { withCredentials: true }
      );
      setUserJournals(response.data);
    } catch (error) {
      console.log(error);
      setUserJournals(null);
    }
  };

  const retrieveClientsAppleHealthData = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/appleHealth/getAllAppleHealthData`,
        {
          userId: id,
        },
        { withCredentials: true }
      );
      setHealthData(response.data.data);
    } catch (error) {
      console.log(error);
      setHealthData(null);
    }
  };

    const retrieveGeneratedEmotionData = async (user, start, end) => {
        try {
            setIsMoodChartLoading(true); // Start loading
            const response = await axios.post(
            `${process.env.REACT_APP_BACK_END_URL}/api/journals/emotionData`,
            {
                userId: user.id,
                startDate: start,
                endDate: end,
            },
            { withCredentials: true }
            );
            setEmotionData(response.data.emotionData);
        } catch (error) {
            console.log(error);
            setEmotionData([]);
        } finally {
            setIsMoodChartLoading(false); // Stop loading
        }
    };

  const generateSummary = async () => {
    setIsGeneratingSummary(true);
    setUserSummary(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/journals/generateClientJournalSummary`,
        {
          userId: selectedUser.id,
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
        },
        { withCredentials: true }
      );
      setUserSummary(response.data.summary || "");
      setJournalIdMap(response.data.journalIdMap || {});
    } catch (error) {
      console.error("Error generating summary:", error);
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      const fetchData = async () => {
        await retrieveClientJournalEntries(selectedUser, startDate, endDate);
        await retrieveClientsAppleHealthData(selectedUser.id);
        await retrieveGeneratedEmotionData(selectedUser, startDate, endDate);
      };
      fetchData();
    }
  }, [selectedUser, startDate, endDate]);

  return {
    userJournals,
    healthData,
    userSummary,
    isGeneratingSummary,
    journalIdMap,
    emotionData,
    isMoodChartLoading,
    generateSummary,
    retrieveClientJournalEntries,
    retrieveClientsAppleHealthData,
    retrieveGeneratedEmotionData,
  };
};

export default useClientData;