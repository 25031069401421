import React from "react";

const UserListSidebar = ({
  isOpen,
  toggleSidebar,
  isLoading,
  userList,
  searchTerm,
  setSearchTerm,
  setUserNotes,
  setUserJournals,
  handleShowInviteEmailModal,
  handleprocessUserLogout,
  parseUserLastVisit,
  setSelectedUser,
  setStartDate,
  selectedUser,
  requestUnlinkClient,
  clientLimit,
  clientCount,
  retrieveClientJournalEntries,
  startDate,
  endDate,
}) => {
  const sidebarClass = isOpen ? "userList open" : "userList";
  return (
    <div className={sidebarClass}>
      <div className="userListLogo">
        {/* <div className="title">Empath</div> */}
        <div className="invite-client-container">
          <button
            className="invite-client-btn"
            onClick={handleShowInviteEmailModal} 
          >
            Invite New Client
          </button>
        </div>
        <div className="seats-used-container">
          <p className="seats-used">{clientCount}/{clientLimit} Seats Used</p>
        </div>
      </div>

      <input
        type="text"
        placeholder="Search for clients.."
        className="searchBar"
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
      />

      <div className="subUserList">
        {isLoading ? (
          <div className="loadingContainer">
            <div className="spinner-grow text-light" role="status"></div>
            <div
              className="spinner-grow text-light me-2 ms-2"
              role="status"
            ></div>
            <div className="spinner-grow text-light" role="status"></div>
          </div>
        ) : (
          userList
            .filter((user) =>
              user.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((user) => (
              <div
                key={user.id}
                className={`user ${
                  selectedUser && selectedUser.id === user.id
                    ? "selectedUser"
                    : ""
                }`}
                onClick={() => {
                  setSelectedUser(user);
                  setUserNotes(user.notes ?? "No notes yet... click to edit");
                  retrieveClientJournalEntries(user, startDate, endDate);
                  const oneMonthAgo = new Date();
                  oneMonthAgo.setDate(oneMonthAgo.getDate() - 31);
                  setStartDate(oneMonthAgo);
                }}
              >
                <div className="user-name">
                {user.profile_picture && (
                <img
                  src={user.profile_picture}
                  alt={`${user.name}'s profile`}
                  className="user-img"
                />
              )}
                  <div className="user-name-title">
                    {user.name}
                  </div>
                </div>

                <button
                  className="Unlink-user-btn"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the onClick of the parent div
                    requestUnlinkClient(user.id);
                  }}
                >
                  Unlink
                </button>
              </div>
            ))
        )}
      </div>

      <div className="processUserLogoutButtonContainer p-3">
        <button
          className="btn btn-secondary w-100"
          onClick={handleprocessUserLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserListSidebar;
