import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './HealthChart.css';
import { color } from 'd3';

const AppleHealthDataChart = ({ timeFilter, healthData, startDate, endDate }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDates, setFilteredDates] = useState([]);
  const [selectedType, setSelectedType] = useState('steps');
  const [average, setAverage] = useState(0);
  const [internalTimeFilter, setInternalTimeFilter] = useState(timeFilter);

  const sleepTypes = [
    'inBed',
    'asleepUnspecified',
    'awake',
    'asleepCore',
    'asleepDeep',
    'asleepREM'
  ];
  
  useEffect(() => {
    const filterData = () => {
      let filtered;
      const now = new Date();
      const oneWeekAgo = new Date();
      const twoWeeksAgo = new Date();
      const oneMonthAgo = new Date();
      
      oneWeekAgo.setDate(now.getDate() - 7);
      twoWeeksAgo.setDate(now.getDate() - 14);
      oneMonthAgo.setMonth(now.getMonth() - 1);

      // Filter by selected type
      let typeFilteredData;
      if (selectedType === 'sleep') {
        typeFilteredData = healthData.filter(item => sleepTypes.includes(item.type));
      } else {
        typeFilteredData = healthData.filter(item => item.type === selectedType);
      }

      // Sort healthData by startDate
      const sortedHealthData = typeFilteredData.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

      if (internalTimeFilter) {
        switch (internalTimeFilter) {
          case '1Week':
            filtered = sortedHealthData.filter(item => new Date(item.startDate) >= oneWeekAgo);
            break;
          case '2Week':
            filtered = sortedHealthData.filter(item => new Date(item.startDate) >= twoWeeksAgo);
            break;
          case '1Month':
            filtered = sortedHealthData.filter(item => new Date(item.startDate) >= oneMonthAgo);
            break;
          default:
            filtered = sortedHealthData;
        }
      } else {
        filtered = sortedHealthData.filter(item => 
          new Date(item.startDate) >= startDate && new Date(item.startDate) <= endDate
        );
      }

      const filteredDates = Array.from(new Set(filtered.map(item => new Date(item.startDate).toLocaleDateString()))).sort((a, b) => new Date(a) - new Date(b));
      setFilteredData(filtered);
      setFilteredDates(filteredDates);

      // Calculate average
      if (selectedType === 'sleep') {
        const totalSleep = filtered.reduce((acc, item) => acc + item.value, 0);
        setAverage((totalSleep / filtered.length / 3600).toFixed(1)); // Convert to hours, 1 decimal point
      } else if (selectedType === 'steps') {
        const total = filtered.reduce((acc, item) => acc + item.value, 0);
        setAverage(Math.round(total / filtered.length)); // Round to nearest integer
      } else if (selectedType === 'distance') {
        const total = filtered.reduce((acc, item) => acc + item.value, 0);
        setAverage(Math.round(total / filtered.length)); // Round to nearest meter
      } else {
        const total = filtered.reduce((acc, item) => acc + item.value, 0);
        setAverage((total / filtered.length).toFixed(2)); // Keep 2 decimal points for other types
      }
    };
    if (healthData && healthData.length > 0) {
      filterData();
    }
  }, [timeFilter, healthData, selectedType, startDate, endDate, internalTimeFilter]);

  const handleInternalTimeFilterChange = (newTimeFilter) => {
    setInternalTimeFilter(newTimeFilter);
  };

  useEffect(() => {
    // Reset internal time filter when dashboard dates change
    setInternalTimeFilter(null);
  }, [startDate, endDate]);

  const getDataByType = (type) => {
    return filteredDates.map(date => {
      const item = filteredData.find(item => new Date(item.startDate).toLocaleDateString() === date && item.type === type);
      return item ? (item.value / 3600) : 0;
    });
  };

  const data = {
    labels: filteredDates,
    datasets: selectedType === 'sleep' ? [
      {
        label: 'In Bed',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        data: getDataByType('inBed'),
        fill: true,
      },
      {
        label: 'Asleep Unspecified',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        data: getDataByType('asleepUnspecified'),
        fill: true,
      },
      {
        label: 'Awake',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        data: getDataByType('awake'),
        fill: true,
      },
      {
        label: 'Asleep Core',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        data: getDataByType('asleepCore'),
        fill: true,
      },
      {
        label: 'Asleep Deep',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        data: getDataByType('asleepDeep'),
        fill: true,
      },
      {
        label: 'Asleep REM',
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderColor: 'rgba(255, 206, 86, 1)',
        data: getDataByType('asleepREM'),
        fill: true,
      },
    ] : [
      {
        label: selectedType.charAt(0).toUpperCase() + selectedType.slice(1),
        backgroundColor: '#0A53BD', 
        borderWidth: 2,
        borderColor: '#000000',
        data: filteredData.map(item => item.value),
      }
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: selectedType === 'sleep',
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          color: '#ffffff', // White text for better visibility on dark background
          font: {
            size: 14 // Adjust as needed
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)' // Subtle grid lines
        },
        ticks: {
          color: '#808080',
          callback: function(value, index, values) {
            const date = new Date(this.getLabelForValue(value));
            if (timeFilter === '1Week') {
              // Show day of the week for 1 week filter
              return date.toLocaleDateString('en-US', { weekday: 'short' });
            } else {
              // Show DD-MM for other filters
              return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' });
            }
          }
        }
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)' // Subtle grid lines
        },
        ticks: {
          color: '#808080' 
        }
      }
    }
  };

  const getUnitLabel = () => {
    switch (selectedType) {
      case 'sleep':
        return 'hours';
      case 'steps':
        return 'steps';
      case 'distance':
        return 'm';
      case 'activeEnergyBurned':
      case 'dietaryEnergyConsumed':
        return 'kcal';
      case 'exerciseMinutes':
        return 'minutes';
      default:
        return '';
    }
  };

  const getButtonClass = (buttonType, value) => {
    if (buttonType === 'type') {
      return `filter-type-button ${selectedType === value ? 'selected' : ''}`;
    } else if (buttonType === 'date') {
      return `filter-date-type-button ${timeFilter === value ? 'selected' : ''}`;
    }
  };

  return (
    <div className='chart-parent-container'>
      <div className='chart-title'>
        Health Data Chart
      </div>
      <div className="button-container">
        <div className="type-buttons">
          <button
            onClick={() => setSelectedType('steps')}
            className={getButtonClass('type', 'steps')}
          >
            Steps
          </button>
          <button
            onClick={() => setSelectedType('sleep')}
            className={getButtonClass('type', 'sleep')}
          >
            Sleep
          </button>
          <button
            onClick={() => setSelectedType('distance')}
            className={getButtonClass('type', 'distance')}
          >
            Distance
          </button>
          <button
            onClick={() => setSelectedType('activeEnergyBurned')}
            className={getButtonClass('type', 'activeEnergyBurned')}
          >
            Energy Burned
          </button>
          <button
            onClick={() => setSelectedType('dietaryEnergyConsumed')}
            className={getButtonClass('type', 'dietaryEnergyConsumed')}
          >
            Energy Consumed
          </button>
          <button
            onClick={() => setSelectedType('exerciseMinutes')}
            className={getButtonClass('type', 'exerciseMinutes')}
          >
            Exercise
          </button>
        </div>
        <div className="time-buttons">
          <button onClick={() => handleInternalTimeFilterChange('1Week')} className={getButtonClass('date', '1Week')}>1 Week</button>
          <button onClick={() => handleInternalTimeFilterChange('2Week')} className={getButtonClass('date', '2Week')}>2 Weeks</button>
          <button onClick={() => handleInternalTimeFilterChange('1Month')} className={getButtonClass('date', '1Month')}>1 Month</button>
        </div>
      </div>
      <div className="average-container">
        <span className="average-label">Average {selectedType}: </span>
        <span className="average-value">
          {average} {getUnitLabel()}
        </span>
      </div>
      {selectedType === 'sleep' ? (
        <Line className='chart-container' data={data} options={options} />
      ) : (
        <Bar className='chart-container' data={data} options={options} />
      )}
    </div>
  );
};

export default AppleHealthDataChart;