import React from 'react';

const MoodTintPillView = ({ moodTint, intensity }) => {
    const containerStyle = {
        position: 'relative',
        display: 'inline-block',
        width: '40px',
        height: '10px',
        marginRight: '5px',
    };

    const moodTintStyle = {
        backgroundColor: moodTint,
        width: '100%',
        height: '100%',
        borderRadius: '10%',
        border: '0.5px solid #000000',
    };

    // Convert intensity to a percentage for positioning
    const intensityPercentage = (intensity + 1) * 50; // intensity ranges from -1 to 1, so convert it to 0% to 100%

    const moodIntensityIndicator = {
        position: 'absolute',
        top: '50%',
        left: `${intensityPercentage}%`, // position it within the container based on intensity
        transform: 'translate(-50%, -50%)',
        width: '5px',
        height: '10px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #000000',
    };

    return (
        <div style={containerStyle}>
            <div style={moodTintStyle}></div>
            <div style={moodIntensityIndicator}></div>
        </div>
    );
}

export default MoodTintPillView;
