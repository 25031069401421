import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const LoginExistingClient = () => {
  const { signUpCode, token } = useParams();
  const signUpURL = `/sign-up-client/${signUpCode}/${token}`;
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        !userData.email ||
        !userData.password
      ) {
        setError("Please fill in all fields.");
        return;
      }

      const res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/existingClientLoginForLinking`,
        { ...userData, signUpCode, token }
      );
      const userId = res.data.userId;
      const jwtToken = res.data.token;

      const therapistRes = await axios.get(
        `${process.env.REACT_APP_BACK_END_URL}/api/therapists/getTheripistIdBySignupCode/${signUpCode}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const therapistId = therapistRes.data.therapist_id;
      await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/associateExistingClientWithTherapist`,
        { userId, therapistId },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      navigate("/success?existing=true");
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 404) {
          setError("User not found. (404)");
        } else if (status === 400) {
          setError("User is still linked to another therapist. Please unlink then try again. (400)");
        } else {
          setError(`Error: ${error.message}`);
        }
      } else if (error.request) {
        setError("Network Error: Unable to connect to the server.");
      } else {
        setError(`Error: ${error.message}`);
      }
    }
  };

  return (
    <div className="login-page" style={{
      fontFamily: "'Inter', sans-serif",
      color: '#333',
      minHeight: '100vh',
      background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <div className="login-background" style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0
      }}>
        <div className="login-shape login-shape-1" style={{
          position: 'absolute',
          width: '300px',
          height: '300px',
          borderRadius: '50%',
          background: 'rgba(100, 200, 255, 0.1)',
          top: '-100px',
          right: '-100px'
        }}></div>
        <div className="login-shape login-shape-2" style={{
          position: 'absolute',
          width: '200px',
          height: '200px',
          borderRadius: '50%',
          background: 'rgba(255, 100, 100, 0.1)',
          bottom: '-50px',
          left: '-50px'
        }}></div>
      </div>
      <Container style={{ position: 'relative', zIndex: 1 }}>
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <div className="login-card" style={{
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              borderRadius: '10px',
              padding: '40px',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)'
            }}>
              <h2 className="text-center mb-4" style={{
                fontSize: '2.5rem',
                fontWeight: 700,
                color: '#007bff'
              }}>Log In</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label className="text-muted">Email address</Form.Label>
                  <div className="input-group">
                    <span className="input-group-text" style={{ backgroundColor: '#007bff', color: 'white' }}>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      onChange={handleInputChange}
                      required
                      style={{ borderColor: '#007bff' }}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="mb-4" controlId="formPassword">
                  <Form.Label className="text-muted">Password</Form.Label>
                  <div className="input-group">
                    <span className="input-group-text" style={{ backgroundColor: '#007bff', color: 'white' }}>
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                    <Form.Control
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      onChange={handleInputChange}
                      required
                      style={{ borderColor: '#007bff' }}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      style={{ borderColor: '#007bff', zIndex: 1 }}
                    >
                      <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
                    </Button>
                  </div>
                </Form.Group>

                <Button 
                  variant="primary" 
                  type="submit" 
                  className="w-100 py-2 fw-bold mb-3"
                  style={{
                    backgroundColor: '#007bff',
                    borderColor: '#007bff',
                    transition: 'all 0.3s ease',
                    position: 'relative',
                    zIndex: 2
                  }}
                  onMouseEnter={(e) => e.target.style.transform = 'translateY(-2px)'}
                  onMouseLeave={(e) => e.target.style.transform = 'translateY(0)'}
                >
                  Log In
                </Button>
              </Form>
              <p className="text-center mb-0">
                Don't have an account?{" "}
                <Button
                  variant="link"
                  onClick={() => navigate(signUpURL)}
                  className="p-0"
                  style={{ color: '#007bff', position: 'relative', zIndex: 2 }}
                >
                  Sign up here
                </Button>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginExistingClient;
