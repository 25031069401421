import React from 'react';
import "./JournalRow.css";
import MoodTintPillView from './MoodTintPillView';

const JournalRow = ({ 
    journal, 
    index, 
    clickedJournalId, 
    setExpandedJournalIndex, 
    expandedJournalIndex, 
    handleJournalClick }) => {
    return (
        <div
        key={index}
        className={`journalEntry ${
          clickedJournalId === journal.journal_id
            ? "journalEntry-clicked"
            : ""
        }`}
        data-journal-id={journal.journal_id}
        onMouseDown={() => {
          setExpandedJournalIndex(
            index === expandedJournalIndex ? -1 : index
          );
          handleJournalClick(journal.journal_id);
        }}
      >
        <div className='titleHorizontal'>
        <h3 className="journalTitle">{journal.title}</h3>
        {
            journal.tintColor && journal.intensity ? 
            <MoodTintPillView moodTint={journal.tintColor} intensity={journal.intensity}/> : null
        }
        </div> 
        <small className="journalDate">
          {new Date(journal.updated_at).toDateString()}
        </small>
        {expandedJournalIndex === index && (
          <p className="journalText">{journal.text}</p>
        )}
      </div>
    );
};

export default JournalRow;
