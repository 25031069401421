import React, { useEffect, useState, useRef } from "react";
import { ButtonGroup, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import RadarChart from "./DataVisual";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Dashboard.css";
import moment from "moment";
import Settings from "./Settings/Settings";
import Profile from "./Profile";
import ApproachAvoidanceBar from "./Charts/ApproachAvoidanceBar"; // Adjust the path as necessary
import UserListSidebar from "./UserListSidebar"; // Update the path as per your file structure
import AppleHealth from "./Charts/HealthChart";
import { useIdleTimer } from "react-idle-timer";
import UnlinkModal from "./UnlinkModal";
import ClientNotesSection from "./ClientNotesSection";
import JournalRow from "./Journals/JournalRow";
import ClientSummary from "./Summary/ClientSummary";
import useClientData from "./Hooks/useClientData";
import useTherapistClients from "./Hooks/useTherapistClients";

const AppleHealthDataType = {
  // Category: Sleep
  INBED: "inBed",
  ASPLEEP_UNSPECIFIED: "asleepUnspecified",
  AWAKE: "awake",
  ASLEEP_CORE: "asleepCore",
  ASLEEP_DEEP: "asleepDeep",
  ASLEEP_REM: "asleepREM",
  SLEEP: "sleep",

  // Quantity
  STEPS: "steps",
  DISTANCE: "distance",
  ACTIVE_ENERGY_BURNED: "activeEnergyBurned",
  DIETARY_ENERGY_CONSUMED: "dietaryEnergyConsumed",
  EXERCISE_MINUTES: "exerciseMinutes",
};
Object.freeze(AppleHealthDataType);

function TherapistDashboard() {
  const { userList, selectedUser, setSelectedUser, isLoading, refreshClients } = useTherapistClients();
  const [clientNotes, setClientNotes] = useState([]);
  const [isNotesOverlayOpen, setIsNotesOverlayOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [selectedNavItem, setSelectedNavItem] = useState("Home");
  const navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  const [expandedJournalIndex, setExpandedJournalIndex] = useState(-1);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState("");
  const [userNotes, setUserNotes] = useState("No notes yet... click to edit");
  const healthDataRef = useRef(null);
  const [isJournalLoading, setIsJournalLoading] = useState(false);
  const [showInviteEmailModal, setShowInviteEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErrorText, setEmailErrorText] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showUnlinkConfirmation, setShowUnlinkConfirmation] = useState(false);
  const [clientToUnlink, setClientToUnlink] = useState(null);
  const [timeFilter, setTimeFilter] = useState("1week");
  const [averageApproachWithdrawalScore, setAverageApproachWithdrawalScore] =
    useState(0);
  const [showRemoveClientsModal, setShowRemoveClientsModal] = useState(false);
  const [emailErrorHtml, setEmailErrorHtml] = useState(null);
  const [showSummary, setShowSummary] = useState(false);

  const {
    userJournals,
    healthData,
    userSummary,
    isGeneratingSummary,
    journalIdMap,
    emotionData,
    isMoodChartLoading,
    generateSummary,
    retrieveClientJournalEntries,
    retrieveClientsAppleHealthData,
    retrieveGeneratedEmotionData,
    } = useClientData(selectedUser, startDate, endDate);

  const handleDateChange = (daysAgo) => {
    const dateAgo = new Date();
    dateAgo.setDate(dateAgo.getDate() - daysAgo);
    setStartDate(dateAgo);
    setEndDate(new Date());
    setTimeFilter(
      daysAgo === 31 ? "1month" :
      daysAgo === 14 ? "2weeks" :
      daysAgo === 7 ? "1week" :
      daysAgo === 1 ? "1day" : ""
    );
  };

  useEffect(() => {
    if (selectedUser) {
      localStorage.setItem("selectedClient", JSON.stringify(selectedUser));
    }
  }, [selectedUser]);

  useEffect(() => {
    setShowSummary(false);
  }, [selectedUser]);

  const [clickedJournalId, setClickedJournalId] = useState(null);
  const handleJournalClick = (journalId) => {
    setClickedJournalId(journalId);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (!event.target.closest(".journalEntry")) {
        setClickedJournalId(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const calculateAverageScore = () => {
      if (userJournals && userJournals.length > 0) {
        const totalScore = userJournals.reduce(
          (acc, journal) => acc + (journal.approach_withdrawal || 0),
          0
        );
        const averageScore = totalScore / userJournals.length;
        setAverageApproachWithdrawalScore(averageScore);
      } else {
        setAverageApproachWithdrawalScore(0);
      }
    };
    calculateAverageScore();
  }, [userJournals, selectedUser, startDate, endDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Invite client
  const sendClientInviteLink = async () => {
    if (!isValidEmail) {
      setEmailErrorText("Please enter a valid email address");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/therapists/sendClientInviteLink`,
        { email },
        { withCredentials: true }
      );
      console.log(response.data);
      setShowInviteEmailModal(false);
      setEmail("");
      setEmailErrorText(null); // Reset any existing error message
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setEmailErrorText(null); // Reset text error
        setEmailErrorHtml({ __html: error.response.data.message });
      } else {
        console.error("Error sending email", error);
        setEmailErrorText("Error sending email");
        setEmailErrorHtml(null); // Reset HTML error
      }
    }
  };

  const handleGenerateSummary = () => {
    setShowSummary(true);
    generateSummary();
  };

  const handleScrollToHealth = () => {
    if (healthDataRef.current) {
      healthDataRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCloseInviteEmailModal = () => {
    setShowInviteEmailModal(false);
    setEmail("");
    setEmailErrorText(null);
  };
  const handleShowInviteEmailModal = () => setShowInviteEmailModal(true);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value));
  };

  const parseUserLastVisit = (last_visited) => {
    console.log("lastVisit", last_visited);
    if (last_visited === null) {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      return date;
    }

    const date = new Date(last_visited);
    if (!isNaN(date.getTime())) {
      return date;
    } else {
      console.error(`Invalid date string: ${last_visited}`);
      return new Date();
    }
  };

  const renderContent = () => {
    switch (selectedNavItem) {
      case "Home":
        return (
          <div>
            {selectedUser == "" ? (
              <>
                <div className="selectUserText">
                  <p className="dashboardSubtitle">
                    Select a user to view their summary
                  </p>
                </div>
              </>
            ) : (
              <div className="dashboardUserSummary">
                <div className="profileAndDates">
                  <div className="profileSection">
                    <div className="profileLeft">
                      <img
                        src={selectedUser.profile_picture}
                        alt="User's Profile"
                        className="profileImage"
                      />
                    </div>
                    <div className="profileRight">
                      <div className="profileInfo">
                        <div className="profileDetails">
                          <h3 className="profileTitle">{selectedUser.name}</h3>
                          <p className="lastVisited-text">
                            Last visited:{" "}
                            {moment(selectedUser.last_visited).isValid()
                              ? moment(selectedUser.last_visited).format(
                                  "MM-DD-YYYY"
                                )
                              : "Not logged yet"}
                          </p>
                        </div>
                        <div className="profileActions">
                          <button
                            className="notesButton"
                            onClick={() =>
                              setIsNotesOverlayOpen(!isNotesOverlayOpen)
                            }
                          >
                            Notes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ClientNotesSection
                    selectedUser={selectedUser}
                    clientNotes={clientNotes}
                    setClientNotes={setClientNotes}
                    isNotesOverlayOpen={isNotesOverlayOpen}
                    setIsNotesOverlayOpen={setIsNotesOverlayOpen}
                  />
                  <div className="datesContainer">
                    <div className="col-sm-6 pe-2">
                      <div className="dateContainer">
                        <label className="dateText">Start Date:</label>
                        <input
                          type="date"
                          className="dateInput"
                          value={startDate.toISOString().split("T")[0]}
                          onChange={(e) =>
                            setStartDate(new Date(e.target.value))
                          }
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 col-sm-6 ps-2">
                      <div className="dateContainer">
                        <label className="dateText">End Date:</label>
                        <input
                          type="date"
                          className="dateInput"
                          value={endDate.toISOString().split("T")[0]}
                          onChange={(e) => setEndDate(new Date(e.target.value))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <ClientSummary
                  userSummary={userSummary}
                  isGeneratingSummary={isGeneratingSummary}
                  journalIdMap={journalIdMap}
                  onGenerateSummary={handleGenerateSummary}
                  onDateChange={handleDateChange}
                  userJournals={userJournals}
                  onScrollToHealth={handleScrollToHealth}
                  showSummary={showSummary}
                  setExpandedJournalIndex={setExpandedJournalIndex}
                />
                <div className="summarySection">
                  <div className="chartsContainer">
                    {healthData != null && healthData.length > 0 ? (
                      <div className="healthChart" ref={healthDataRef}>
                        <AppleHealth
                          healthData={healthData}
                          timeFilter={timeFilter}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                    ) : null}
                    {userJournals != null && userJournals.length > 0 ? (
                      <div className="radarChartContainer">
                        <h3 className="radarChartTitle">Mood Chart</h3>
                        {isMoodChartLoading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "200px" }}
                          >
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <ul className="moodChartLegend">
                              <li>
                                <span className="inferredMoods"></span>Estimated
                              </li>
                              <li>
                                <span className="clientMoods"></span>Self-Reported
                              </li>
                            </ul>
                            <RadarChart data={emotionData} />
                          </>
                        )}
                        <ApproachAvoidanceBar
                          averageScore={averageApproachWithdrawalScore}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="userJournals">
                    {isJournalLoading ? (
                      <div className="loadingContainer mt-3">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                        <div
                          className="spinner-grow text-light me-2 ms-2"
                          role="status"
                        ></div>
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    ) : userJournals != null && userJournals.length > 0 ? (
                      <ul className="journalList">
                      {userJournals.map((journal, index) => (
                        <li key={index} className="journalListItem">
                          <JournalRow
                            journal={journal}
                            index={index}
                            clickedJournalId={clickedJournalId}
                            setExpandedJournalIndex={setExpandedJournalIndex}
                            expandedJournalIndex={expandedJournalIndex}
                            handleJournalClick={handleJournalClick}
                          />
                        </li>
                      ))}
                    </ul>
                    ) : (
                      <div className="noJournalsMessage"></div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      case "Profile":
        return <Profile />;
      case "Settings":
        return <Settings user={selectedUser} />;
      default:
        return <div>Select a tab to see the content</div>;
    }
  };

  const handleprocessUserLogout = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACK_END_URL}/api/users/processUserLogout`,
        {},
        { withCredentials: true }
      )
      .then((response) => {
        console.log("successfully logged out");
        localStorage.removeItem("selectedClient"); // Clear the stored selected client
        navigate("/");
      })
      .catch((error) => {
        console.error("processUserLogout failed", error);
      });
  };

  //Idle timer functions-----------------------

  const timeout = 60 * 60 * 1000;
  const promptBeforeIdle = 60 * 5 * 1000;

  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const onIdle = () => {
    setOpen(false);
    handleprocessUserLogout();
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleStillHere = () => {
    activate();
  };

  //-----------------------

  const [missingSettings, setMissingSettings] = useState(false);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_URL}/api/therapists/retrieveTherapistPreferences`,
        { withCredentials: true }
      );
      console.log("Settings: ", response.data);
      setMissingSettings(Object.keys(response.data).length === 0);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  const closeSettingsModal = () => {
    setMissingSettings(false);
  };

  const requestUnlinkClient = (clientId) => {
    setClientToUnlink(clientId);
    setShowUnlinkConfirmation(true);
  };

  const handleUnlinkClient = async () => {
    if (!clientToUnlink) {
      return;
    }
    const userId = clientToUnlink;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/users/unlinkClientFromTherapist`,
        { userId },
        { withCredentials: true }
      );
      const data = await response.json();
      if (data.message) {
        console.log(data.message);
        refreshClients();
      }
    } catch (error) {
      console.error("Error unlinking client: ", error);
    }
    setClientToUnlink(null);
    setShowUnlinkConfirmation(false);
  };

  const [clientLimit, setClientLimit] = useState();
  const [clientCount, setClientCount] = useState();

  const fetchClientLimit = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_URL}/api/therapists/retrieveTherapistClientLimit`,
        {
          withCredentials: true,
        }
      );
      setClientLimit(response.data.client_limit);
      setClientCount(response.data.client_count);
    } catch (error) {
      console.log("Error fetching client limit/count", error);
    }
  };
  useEffect(() => {
    fetchClientLimit();
  }, [clientCount, clientLimit]);

  return (
    <div className="dashboardContainer">
      {/* Toggle Button for Sidebar */}
      <button
        onClick={toggleSidebar}
        className={`sidebar-toggle-btn ${isNotesOverlayOpen ? "hidden" : ""}`}
      >
        Client List
      </button>
      <Modal show={showInviteEmailModal} onHide={handleCloseInviteEmailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Send out invite email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {emailErrorHtml ? (
            <div
              className="alert alert-danger"
              dangerouslySetInnerHTML={emailErrorHtml}
            />
          ) : (
            emailErrorText && (
              <div className="alert alert-danger">{emailErrorText}</div>
            )
          )}

          <input
            type="email"
            className="form-control p-3"
            value={email}
            onChange={handleEmailChange}
            style={{
              width: "100%",
              borderColor: isValidEmail ? "lightgray" : "red",
            }}
            placeholder="Enter email address"
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <button
            className="btn btn-secondary p-2 m-1"
            onClick={handleCloseInviteEmailModal}
          >
            Close
          </button>
          <button
            className="btn btn-primary p-2 m-1"
            onClick={sendClientInviteLink}
          >
            Send Invite
          </button>
        </Modal.Footer>
      </Modal>

      <UserListSidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        isLoading={isLoading}
        userList={userList}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setSelectedUser={setSelectedUser}
        setUserNotes={setUserNotes}
        handleShowInviteEmailModal={handleShowInviteEmailModal}
        handleprocessUserLogout={handleprocessUserLogout}
        parseUserLastVisit={parseUserLastVisit}
        selectedUser={selectedUser}
        setStartDate={setStartDate}
        handleUnlinkClient={handleUnlinkClient}
        requestUnlinkClient={requestUnlinkClient}
        clientLimit={clientLimit}
        clientCount={clientCount}
        retrieveClientJournalEntries={retrieveClientJournalEntries}
        startDate={startDate}
        endDate={endDate}
      />
      <Modal
        show={showUnlinkConfirmation}
        onHide={() => setShowUnlinkConfirmation(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Unlink Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to unlink this client?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowUnlinkConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleUnlinkClient}>
            Unlink
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="dashboardContent">
        <div className="navBar">
          <ButtonGroup
            className="buttonGroupDashboardContent"
            aria-label="Basic example"
          >
            <Button
              variant={selectedNavItem === "Home" ? "primary" : "secondary"}
              onClick={() => setSelectedNavItem("Home")}
            >
              Home
            </Button>
            <Button
              variant={selectedNavItem === "Profile" ? "primary" : "secondary"}
              onClick={() => setSelectedNavItem("Profile")}
            >
              Profile
            </Button>
            <Button
              variant={selectedNavItem === "Settings" ? "primary" : "secondary"}
              onClick={() => setSelectedNavItem("Settings")}
            >
              Settings
            </Button>
          </ButtonGroup>
        </div>
        <div className="dashContainer">{renderContent()}</div>
      </div>
      <div className="idleModal" style={{ display: open ? "flex" : "none" }}>
        <div className="idle-modal-box">
          <h3>Are you still here?</h3>
          <p>Logging out in {remaining} seconds</p>
          <div className="button-container">
            <button onClick={handleStillHere}>I'm still here</button>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={missingSettings}
          onHide={() => setMissingSettings(false)}
          dialogClassName="settings-modal"
        >
          <Modal.Header>
            <Modal.Title>Finish Setting Up</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Settings closeSettingsModal={closeSettingsModal} />
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <UnlinkModal
          show={showRemoveClientsModal}
          onHide={() => setShowRemoveClientsModal(false)}
          showModal={() => setShowRemoveClientsModal(true)}
        />
      </div>
    </div>
  );
}

export default TherapistDashboard;
