import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf.js';
import { marked } from 'marked';
import TranscribeAudio from './TranscribeAudio';
import dictateIcon from './assets/dictate-icon.png';
import backIcon from './assets/back-icon.png';
import saveIcon from './assets/save-icon.png';
import closeIcon from './assets/close-icon.png';
import noteIcon from './assets/note-icon.png';
import stopIcon from './assets/stop.png';
import loadingGif from './assets/loading-animation.gif'; 
import recordingGif from './assets/recording-animation.gif'; 
import ReactMarkdown from 'react-markdown';
import { ButtonGroup, Button, Modal } from "react-bootstrap";
import "./Dashboard.css";

function ClientNotesSection({ selectedUser, clientNotes, setClientNotes, isNotesOverlayOpen, setIsNotesOverlayOpen }) {
    const textareaRef = useRef(null);
    const [newNote, setNewNote] = useState('');
    const [expandedNoteId, setExpandedNoteId] = useState(null); // Ensure this line is correctly added
    const [showTranscribe, setShowTranscribe] = useState(false); // State to control visibility of TranscribeAudio
    const [isTranscribing, setIsTranscribing] = useState(false); // State to indicate transcribing process
    const [showNoteInput, setShowNoteInput] = useState(false); // New state to control visibility of the note input
    const [selectedTemplate, setSelectedTemplate] = useState('default');
    const [progressReport, setProgressReport] = useState(null);
    const [isProgressReportLoading, setIsProgressReportLoading] = useState(false);
    const [isNoteGenerating, setIsNoteGenerating] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [emailId, setEmailId] = useState('');
    const [therapistEmail, setTherapistEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Added state for loading
  
  
    const handleEmailPDF = async () => {
      if (!emailId.trim()) {
        alert('Please enter a valid email ID');
        return;
      }
    
      try {
        if (!progressReport) {
          alert('Progress report not generated');
          return;
        }
    
        // Create a HTML template for the progress report with the company letterhead
        const progressReportHTML = `
          <div style="font-family: Arial, sans-serif; padding: 20px;">
            <header style="border-bottom: 2px solid #000; padding-bottom: 10px; margin-bottom: 20px;">
              <div style="display: flex; justify-content: space-between; align-items: center;">
                <img src={companyLogo} alt="Company Logo" style={{ height: '50px' }} />
                <div style="text-align: right;">
                  <h1 style="margin: 0; font-size: 24px;">Your Company Name</h1>
                  <p style="margin: 0; font-size: 14px;">1234 Street Address, City, State, ZIP</p>
                  <p style="margin: 0; font-size: 14px;">Phone: (123) 456-7890 | Email: contact@company.com</p>
                </div>
              </div>
            </header>
            <main>
              <div class="markdown-body">
                ${marked.parse(progressReport)}
              </div>
            </main>
            <footer style="border-top: 2px solid #000; padding-top: 10px; margin-top: 20px; text-align: center; font-size: 12px;">
              <p>&copy; ${new Date().getFullYear()} Company Name. All rights reserved.</p>
            </footer>
          </div>
        `;
    
        // Use html2pdf to generate the PDF
        const opt = {
          margin: 1,
          filename: 'progress_report.pdf',
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
    
        const pdfBlob = await html2pdf().from(progressReportHTML).set(opt).outputPdf('blob');
        console.log('PDF blob created:', pdfBlob);
    
        // Create a FormData object to send the PDF as an attachment
        const formData = new FormData();
        formData.append('userId', selectedUser.id);
        formData.append('emailId', emailId);
        formData.append('pdfBlob', pdfBlob, 'progress_report.pdf');
        console.log('FormData contents:', formData.get('userId'), formData.get('emailId'), formData.get('pdfBlob'));
    
        // Send the email with the PDF attachment
        await axios.post(`${process.env.REACT_APP_BACK_END_URL}/api/therapists/notes/emailPDF`, formData, {
          withCredentials: true,
        });
    
        alert('Progress report PDF sent successfully');
        setShowEmailModal(false);
        setEmailId('');
      } catch (error) {
        console.error('Failed to send progress report PDF:', error);
        alert('Failed to send progress report PDF');
      }
    };
  
    const handleCloseEmailModal = () => {
      setShowEmailModal(false);
      setEmailId('');
    };
  
    const handleCloseOverlay = useCallback(() => {
      setIsNotesOverlayOpen(false);
      setShowNoteInput(false); // Add this line to reset the note input visibility
      setNewNote(''); 
    }, [setIsNotesOverlayOpen]);
  
    const handleCancelNote = useCallback(() => {
      setShowNoteInput(false); // Hide the textarea and show the notes list
      setNewNote(''); // Clear the textarea content
    }, []);
    
    const fetchNotes = useCallback(async () => {
      if (selectedUser && selectedUser.id) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BACK_END_URL}/api/journals/notes/fetch`, {
            userId: selectedUser.id
          }, {
            withCredentials: true,
          });
          setClientNotes(response.data.map(note => ({
            date: note.created_at,
            content: note.note_content,
          })));
        } catch (error) {
          console.error("Failed to fetch notes:", error);
        }
      } 
    }, [selectedUser, setClientNotes]);
  
    useEffect(() => {
      if (isNotesOverlayOpen) {
        fetchNotes();
      }
    }, [isNotesOverlayOpen, fetchNotes]);
  
    useEffect(() => {
      if (showNoteInput) {
        textareaRef.current.focus();
      }
    }, [showNoteInput]);
  
    useEffect(() => {
      const fetchTherapistEmail = async () => {
        try {
          const therapistId = sessionStorage.getItem('therapistId');
          const response = await axios.get(`${process.env.REACT_APP_BACK_END_URL}/api/therapists/retrieveTherapistDetails`, {
            withCredentials: true,
          });
          setTherapistEmail(response.data.email);
        } catch (error) {
          console.error('Error fetching therapist email:', error);
        }
      };
  
      fetchTherapistEmail();
    }, []);
    
    const handleWriteNote = useCallback(() => {
      setShowNoteInput(true); // Show the textarea and hide the notes list
    }, []);
  
    const handleSaveNote = useCallback(async () => {
      if (!newNote.trim()) return;
      if (!selectedUser || !selectedUser.id) {
        alert("No user selected");
        return;
      }
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACK_END_URL}/api/journals/notes/create`, {
          userId: selectedUser.id,
          noteContent: newNote,
        }, {
          withCredentials: true,
        });
        if (response.status === 200) {
          fetchNotes();
          setNewNote('');
          setShowNoteInput(false); // Hide the textarea and show the notes list
        }
      } catch (error) {
        console.error("Failed to create note:", error);
      }
    }, [newNote, selectedUser, fetchNotes]);
    
  
    const handleRecordNote = useCallback(() => {
      setShowTranscribe(true);
    }, []);
  
  
    function handleNoteClick(noteContent) {
      setShowNoteInput(true); // Show the textarea
      setNewNote(noteContent); // Populate the textarea with the note's content
    }
    
    const toggleNoteExpansion = (noteId) => {
      setExpandedNoteId(expandedNoteId === noteId ? null : noteId);
    };
  
    const handleToggleTranscription = () => {
      if (!isTranscribing) {
        setIsTranscribing(true);
        setIsLoading(true); // Set loading state to true only when starting
        setTimeout(() => {
          setIsLoading(false); // Hide loading GIF after 3 seconds
        }, 3000); // 3-second delay
      } else {
        setIsTranscribing(false); // Stop transcription immediately
      }
    };
  
    const handleTranscriptionComplete = (transcription) => {
      console.log('Transcription Complete:', transcription);
    };
  
    const templates = {
      default: {
        date: "[date]",
        client_name: "[client_name]",
        session_overview: "[session_overview]",
        key_issues_discussed: [
          "[issue_1]",
          "[issue_2]",
          "[...]"
        ],
        therapist_observations: "[observations]",
        action_items: "[action_items]"
      },
      soap: {
        date: "[date]",
        client_name: "[client_name]",
        subjective: "[subjective]",
        objective: "[objective]",
        assessment: "[assessment]",
        plan: "[plan]"
      },
      dap: {
        date: "[date]",
        client_name: "[client_name]",
        data: "[data]",
        assessment: "[assessment]",
        plan: "[plan]"
      },
      birp: {
        date: "[date]",
        client_name: "[client_name]",
        behavior: "[behavior]",
        intervention: "[intervention]",
        response: "[response]",
        plan: "[plan]"
      },
      pie: {
        date: "[date]",
        client_name: "[client_name]",
        problem: "[problem]",
        intervention: "[intervention]",
        evaluation: "[evaluation]"
      },
      firp: {
        date: "[date]",
        client_name: "[client_name]",
        facts: "[facts]",
        interpretation: "[interpretation]",
        reaction: "[reaction]",
        plan: "[plan]"
      }
    };  
    
    function getTemplate(templateName) {
      return templates[templateName] || templates.default;
    }
    
    const handleGenerateNote = useCallback(async () => {
      if (!newNote || !newNote.trim()) return;
      setIsNoteGenerating(true); // Set loading state to true
      setIsTranscribing(false); // Ensure transcription is stopped when generating a note
    
      try {  
        // Send the request to your backend
        const response = await axios.post(`${process.env.REACT_APP_BACK_END_URL}/api/journals/notes/generatenote`, {
          userId: selectedUser.id,
          therapistInput: newNote,
          templateName: selectedTemplate,
          selectedUser: { name: selectedUser.name } // Add this line 
        }, {
          withCredentials: true,
        });
        if (response && response.data) {
          setNewNote(response.data.noteText);
        }
      } catch (error) {
        console.error("Error generating session note:", error);
      } finally {
        setIsTranscribing(false); // Processing done
        setIsNoteGenerating(false); // Set loading state to false
      }
    }, [newNote, selectedTemplate /* Add any other dependencies here */]);
  
    const handleCreateProgressReport = async () => {
      if (!selectedUser || !selectedUser.id) {
        alert("No user selected");
        return;
      }
      setIsProgressReportLoading(true); // Set loading state to true
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACK_END_URL}/api/journals/notes/summarize`, {
          userId: selectedUser.id,
        }, {
          withCredentials: true,
        });
        if (response.status === 200) {
          setProgressReport(response.data.noteSummary);
        }
      } catch (error) {
        console.error("Failed to create progress report:", error);
        if (error.response && error.response.status === 404) {
          alert(error.response.data.message);
        } else {
          alert("Failed to create progress report");
        }
      } finally {
        setIsProgressReportLoading(false); // Set loading state to false
      }
    };
    
    return (
      <div className={`client-notes-overlay ${isNotesOverlayOpen ? 'open' : ''}`}>
        <div className="client-notes-wrapper">
          <div className={`client-notes-content ${showNoteInput ? 'new-note' : ''}`}>
            <div className="client-notes-header">
              {!showNoteInput && 
              <button onClick={handleWriteNote} className="notes-write-btn">
                <img src={noteIcon} alt="note" /> New Note
              </button>}
              {showNoteInput && (
                <>
                  <button onClick={handleCancelNote} className="notes-back-btn">
                    <img src={backIcon} alt="Back" /> Back
                  </button>
                  <button onClick={handleToggleTranscription}
                  className={`notes-record-btn ${isTranscribing ? 'active' : ''}`}
                  disabled={isLoading} 
                  >
                  {isLoading ? (
                    <img src={loadingGif} alt="Loading" className="loading-gif" />
                  ) : (
                    <img src={isTranscribing ? recordingGif : dictateIcon} alt={isTranscribing ? 'recording' : 'Dictate'} className={isTranscribing ? 'enlarged' : ''} />
                  )}
                </button>
                </>
              )}
              <button onClick={handleCloseOverlay} className="notes-close-btn">
                <img src={closeIcon} alt="close" />Close
              </button>
            </div>
            {showNoteInput ? (
              <>
                <textarea
                  placeholder="Write or Dictate a new note..."
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  ref={textareaRef}
                />
                <div className="client-notes-footer">
                  <button 
                    onClick={handleGenerateNote} 
                    disabled={isNoteGenerating} 
                    className="notes-generate-btn"
                  >
                    {isNoteGenerating ? (
                      <div className="loadingContainer">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    ) : (
                      "Generate Note"
                    )}
                  </button>
                  <div className="template-selector">
                    <select value={selectedTemplate} onChange={(e) => setSelectedTemplate(e.target.value)}>
                      <option value="default">Select template</option>
                      <option value="soap">SOAP</option>
                      <option value="dap">DAP</option>
                      <option value="birp">BIRP</option>
                      <option value="pie">PIE</option>
                      <option value="firp">FIRP</option>
                      <option value="default">Default</option>
                    </select>
                  </div>
                  <button onClick={handleSaveNote} className="notes-save-btn">
                    <img src={saveIcon} alt="Back" /> Save
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="client-notes-list">
                  {clientNotes.map((note, index) => (
                    <div
                      key={index}
                      className={`client-note ${expandedNoteId === index ? 'client-note-clicked' : ''}`}
                      onClick={() => handleNoteClick(note.content)}
                    >
                      <p className="note-date">{new Date(note.date).toLocaleDateString()}</p>
                      {expandedNoteId === index && <p className="note-content">{note.content}</p>}
                    </div>
                  ))}
                </div>
                <div className="client-notes-footer">
                  <button onClick={handleCreateProgressReport} 
                  className="create-progress-report-btn"
                  disabled={isProgressReportLoading} 
                  >
                    {isProgressReportLoading ? (
                      <div className="loadingContainer">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    ) : (
                      "Create Progress Report"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
          {!showNoteInput && (
          <div className="client-progress-section">
            {progressReport && (
              <>
                <div className="progressReportBlock">
                  <ReactMarkdown className="ProgressText">
                    {progressReport}
                  </ReactMarkdown>
                </div>
                <Button onClick={() => setShowEmailModal(true)}>Email PDF</Button>
              </>
            )}
          </div>
        )}
        <TranscribeAudio
                    onTranscriptionComplete={handleTranscriptionComplete}
                    isTranscribing={isTranscribing}
                    setIsTranscribing={setIsTranscribing}
                    setNewNote={setNewNote}
                  />
      </div>
      <Modal show={showEmailModal} onHide={handleCloseEmailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Email Progress Report PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="email"
            className="form-control"
            placeholder="Enter email ID"
            value={emailId || therapistEmail}
            onChange={(e) => setEmailId(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEmailModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEmailPDF}>
            Send PDF
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
  }

  export default ClientNotesSection;